<template>
  <v-form @submit.prevent="onLocalSubmit" ref="form">
    <v-row dense>
      <v-col>
        <v-autocomplete :loading="isLoading('get:paciente')" v-model="currentItem.paciente"  :items="pacientes"
          item-value="id" item-text="nome" outlined dense clearable :rules="requiredField">
          <template v-slot:label>
            <span>ID - Paciente <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete
          :items="procedimentosDaEspecialidade"
          v-model="currentItem.procedimento"
          item-value="id"
          item-text="nome"
          outlined
          dense
          clearable
          :rules="requiredField"
        >
          <template v-slot:label>
            <span>Procedimento <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>

      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <vuetify-money v-model="currentItem.valor" label="Valor" outlined  
       :options="options" dense :rules="requiredField" v-money="currencyMask"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-text-field v-model="currentItem.inicio_vigencia" type="date" outlined clearable dense>
        <template v-slot:label>
            <span>Início da vigência <span style="color: red;">*</span></span>
          </template> 
      </v-text-field>
    </v-row>
    <v-row dense>
      <v-col class="text-end">
          <v-tooltip top :disabled="isFormValid">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
        </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ConfirmButton from '../../../ConfirmButton.vue';
import UtilsFunc from '../../../../service/utilsFunc';
import api from '@/http'

const { withCRUDUtils } = UtilsFunc

export default {
  name: "DadosPessoaisForm",
  props: {
    currentItem:  Object,
    onSubmit: Function,
    loading: Boolean
  },
  components: { ConfirmButton },
  data: () => withCRUDUtils({
    pacientes: [],
    requiredField: [e => e !== undefined || 'Obrigatório'],
    isSubmitting: false,
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },

  }),
  methods: {
    onLocalSubmit() {
      const isValid = this.$refs.form.validate()
      if (!isValid) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
      this.isSubmitting = true; 
      this.onSubmit({
        ...this.currentItem,
      }).catch((error) => {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }).finally(() => {
        this.isSubmitting = false;
      }); 
    },
    async getPaciente() {
      const LOADING_NAME = 'get:paciente'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get("pacientes/app-pacientes/")
        this.pacientes = data.filter(item => item.ativo)
        // console.log(this.pacientes)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    
  },
  computed: {
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    isFormValid() {
      return Boolean(
        this.currentItem.paciente &&
        this.currentItem.valor &&
        this.currentItem.inicio_vigencia &&
        this.currentItem.procedimento
      );
    },
    procedimentosDaEspecialidade() {
      if (
        this.currentItem?.especialidade?.procedimento &&
        Array.isArray(this.currentItem.especialidade.procedimento)
      ) {
        return this.currentItem.especialidade.procedimento.filter(p => p.ativo);
      }
      return [];
  }
  },
 
  mounted() {
    this.getPaciente()
  },
  

}
</script>
